import Vue from 'vue'
import NProgress from 'vue-nprogress'

Vue.use(NProgress,{
    router: false, // Show progressbar when navigating routes, default: true
    true: true // Show progressbar when doing Vue.http, default: true
});
const instance = new NProgress({ showSpinner: false })

export default instance
