import { getField, updateField } from 'vuex-map-fields';

const initialState = function(){
  return {
    space: null,
    inventory: null,
  }
}
const state = initialState();

const getters = {
  getField,
};

const actions = {};

const mutations = {
  updateField,
  space(state,space){
    state.space = space;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
