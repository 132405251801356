import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader
import 'material-icons/iconfont/material-icons.css';

Vue.use(Vuetify);

const vuetify  = new Vuetify({
    icons: {
        iconfont: 'md',
    },
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#cc0605',
                secondary: '#b0bec5',
                accent: '#696969',
            },
            dark: {
                primary: "#063970",
                accent: "#154c79",
                secondary: "#555",
                error: "#dd2b1c",
                warning: "#DD791C",
                info: "#154c79",
                success: "#15A92F"
            },
        },
    },
});
import VuetifyConfirm from 'vuetify-confirm'

Vue.use(VuetifyConfirm, { vuetify })

export default vuetify
