<template>
  <section>
    <v-stepper non-linear v-model="step">
      <v-stepper-header>
        <v-stepper-step
            step="2"
            :rules="[checkOrders]"
            editable
        />
        <v-divider/>
        <v-stepper-step
            step="3"
            editable
            :rules="[checkQuestions]"
        />
        <v-divider/>
        <v-stepper-step
            step="4"
            :rules="[checkImages]"
            editable
        />
        <v-divider/>
        <v-stepper-step
            step="5"
            :rules="[checkComplete]"
            editable
        />
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="2" class="pb-10 vh-100">
          <v-row>
            <v-col>
              <v-list one-line v-if="orders.length > 0">
                <v-list-item v-for="order in orders" :key="order.order_number">
                  <v-list-item-content>
                    <v-list-item-title>{{ order.order_number }}</v-list-item-title>
                    <v-list-item-subtitle>{{ order.delivery_note_number }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="removeOrder(order)">
                      <v-icon>
                        close
                      </v-icon>
                    </v-btn>

                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-alert type="info" v-else>
                Aufträge scannen
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                  :disabled="orders.length === 0"
                  @click="step = 3"
                  block
                  color="primary"
              >
                Weiter
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-content step="3" class="pb-10">
          <checklist-check
              v-for="(question,index) in checklist.checklist_checks"
              :key="question.id"
              :check.sync="checklist.checklist_checks[index]"
          >
          </checklist-check>
          <v-container>
            <v-btn
                @click="step = 4"
                block
                color="primary"
            >
              Weiter
            </v-btn>
          </v-container>
        </v-stepper-content>
        <v-stepper-content step="4" class="pb-10">
          <v-card v-for="collection in checklist.media_collections" class="mb-4" :key="collection.title" >
            <v-card-text>
              {{ collection.title }}
            </v-card-text>
            <file-card :ref="collection.collection" :id="checklist.id" model="checklist" :collection="collection.collection"></file-card>
          </v-card>
          <v-container>
            <v-btn
                @click="step = 5"
                block
                color="primary"
            >
              Weiter
            </v-btn>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
      <v-stepper-items>
        <v-stepper-content step="5" class="pb-10">
          <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>help_outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Alle Aufträge hinzugefügt?</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="checkQuestions()">check</v-icon>
                <v-icon v-else>close</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Alle Fragen beantwortet?</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon v-if="checkImages()">check</v-icon>
                <v-icon v-else>close</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Bilder gemacht?</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-row>
            <v-col>
              <v-alert color="warning" dense><small>Die Checkliste kann nach dem Abschließen nicht weiter bearbeiet werden.</small></v-alert>
              <v-btn :disabled="!checkAll()" block color="success" @click="finish">Abschließen</v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <scanner-area :expected="['inbound','outbound']" @submit="handleScan"/>
  </section>
</template>
<script>

import FileCard from "@/components/FileCard";
import ChecklistCheck from "@/components/checklist/checklist-check";

function initialState() {
  return {
    step: 2,
    checklist: {
      title: null,
      hauler: null,
      hauler_name: null,
      type: null,
      is_dg: false,
      orders: []
    },
  }
}

export default {
  name: 'Checklist',
  components: {
    ChecklistCheck,
    FileCard,
  },
  data: initialState,
  computed: {
    checklist_id: function () {
      if (this.checklist && this.checklist.id != null) {
        return this.checklist.id;
      }
      return null;
    },
    orders: {
      get: function () {
        if (this.checklist.type) {
          if (this.checklist.type === "outbound") {
            return this.checklist.outbound || [];
          }
          if (this.checklist.type === "inbound") {
            return this.checklist.inbound || [];
          }
        }
        return [];
      },
      set: function (orders) {
        if (this.checklist.type) {
          if (this.checklist.type === "outbound") {
            this.checklist.outbound = orders;
          }
          if (this.checklist.type === "inbound") {
            this.checklist.inbound = orders;
          }
        }
      }
    },
  },
  props: {
    id: {
      type: Number,
      required: false,
    },
    scan: {
      type: Object,
      required: false,
    },
  },
  watch: {
    scan: {
      handler: function (scan) {
        if (scan) {
          this.handleScan(scan);
        }
      },
      immediate: true
    },
    id: {
      handler: function (newValue) {
        if (newValue) {
          let _this = this;
          _this.intranet.get('/checklist/' + newValue, {
            params: {
              include: [
                'checklistChecks',
                'outbound',
                'inbound',
                'staff'
              ]
            }
          }).then((response) => {
            _this.checklist = response.data.data;
            if (_this.checklist.completed === true) {
              _this.reset();
              _this.playSound('warning');
              _this.$store.commit("addMessage", {
                type: "warning",
                message: "Checkliste bereits abgeschlossen, wenn Änderungen notwendig sind Freigabe vom WAKO anfordern.",
              });
            }
          }).catch(() => _this.playSound('error'));
        }
      },
      immediate: true
    },
  },
  methods: {
    handleScan(scan) {
      // CHECK IF SCANNED ORDER IS VALID
      let _this = this;

      // CREATE CHECKLIST IF TYPE IS SET AN FIRST SCAN OF ORDER IS DONE
      if (!_this.checklist_id) {
        _this.checklist.type = scan.type
        _this.loading = true;
        _this.intranet.post('/checklist', {
          scan: scan.value,
          item: _this.checklist,
          warehouse: localStorage.getItem('warehouse'),
        }).then((response) => {
          _this.checklist = response.data.data;
          this.playSound('success');
        }).catch(() => {
          this.playSound("error");
        }).finally(() => _this.loading = false);
      } else if (_this.checklist_id) {
        _this.loading = true;
        // ADD ORDER TO LIST
        _this.orders.push({
          id: scan.value
        });
        // SAVE
        _this.intranet.patch('/checklist/' + _this.checklist_id, {
          action: scan.type === 'outbound' ? 'setOutbound' : 'setInbound',
          ids: _this.orders.map(item => item.id),
          include: [
            'checklistChecks',
            'outbound',
            'inbound',
            'staff'
          ]
        }).then((response) => {
          _this.checklist = response.data.data
          _this.playSound('success')
        }).catch(() => {
          _this.playSound('error');
          _this.orders = _this.orders.filter(item => item.id != scan.value);
        }).finally(() => _this.loading = false);
      } else {
        _this.playSound('error');
      }
    },
    finish() {
      let _this = this;
      _this.loading = true;
      _this.intranet.patch('/checklist/' + _this.checklist_id, {
        action: 'finish',
        include: [
          'checklistChecks',
          'outbound',
          'inbound',
          'staff'
        ]
      }).then(() => {
        _this.playSound('success');
        _this.reset();
      }).catch(() => {
        _this.playSound('error');
      }).finally(() => _this.loading = false);
    },
    reset() {
      Object.assign(this.$data, initialState());
    },
    removeOrder(order) {
      this.orders = this.orders.filter(item => item.id != order.id)
    },
    checkAll() {
      return (this.checkQuestions() && this.checkImages());
    },
    checkOrders() {
      return this.orders.length > 0;
    },
    checkQuestions() {
      if (this.checklist && this.checklist.checklist_checks) {
        let invalidAnswers = this.checklist.
        checklist_checks
            .filter(item => item.type !== 'number')
            .filter(item => item.correct_answer === null || (!item.correct_answer && !item.comment))
        return invalidAnswers.length === 0;
      } else {
        return false;
      }
    },
    checkImages() {
      let _this = this;
      if (_this.checklist) {
        if (_this.checklist.media_collections) {
          let invalidMedias = _this.checklist.media_collections.filter((item) => {
            if (item.required === true && _this.$refs[item.collection] && _this.$refs[item.collection][0] && _this.$refs[item.collection][0].files.length > 0) {
              return false;
            } else {
              // ITEM IS INVALID
              return true;
            }
          });
          return invalidMedias.length === 0;
        } else {
          return false;
        }
      }
      return false;
    },
    checkComplete() {
      let _this = this;
      if (_this.checklist && _this.checklist.completed === true) {
        return true;
      }
      return false;
    }
  }
}
</script>