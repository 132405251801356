<template>
  <v-card class="mb-3" :loading="loading" :disabled="loading">
    <v-container fluid>
      {{ check.check }}
      <template v-if="check.type === 'boolean'">
        <v-radio-group v-model="check.correct_answer" @change="update" >
          <v-row>
            <v-col cols="6">
              <v-radio
                  :color="check.answer === true ? 'success' : 'error'"
                  :value="check.answer === true"
                  label="Ja"
              />
            </v-col>
            <v-col cols="6">
              <v-radio
                  :color="check.answer === false ? 'success' : 'error'"
                  :value="check.answer === false"
                  label="Nein"
              />
            </v-col>
          </v-row>
        </v-radio-group>
        <div  v-if="check.correct_answer === false" transition="fade">
          <v-row>
            <v-col>
              <v-textarea
                  filled
                  rows="2"
                  label="Kommentar"
                  v-model="check.comment"
                  @change="update"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <file-card :id="check.id" model="checklist_check"></file-card>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-if="check.type === 'number'">
        <v-row>
          <v-col>
            <v-text-field
                type="number"
                filled
                rows="2"
                :label="check.text"
                v-model="check.comment"
                @change="update"
            />
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-card>
</template>

<script>

import FileCard from "@/components/FileCard";

export default {
  name: 'ChecklistCheck',
  components: {FileCard},
  data() {
    return {
      loading: false,
    };
  },
  props: {
    check: {
      type: Object,
      required: true,
    },
  },
  computed: {},
  methods: {
    update() {
      let _this = this;
      _this.loading = true;
      _this.intranet.put('/checklist-check/' + _this.check.id, {
        item: _this.check
      }).then((response) => {
        _this.$emit('update:check', response.data.data)
      }).finally(() => {
        _this.loading = false
      });
    }
  }
}
</script>