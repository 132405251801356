import Vue from 'vue';

const state = {
    state: {
        gate: {},
        inbounds: [],
        active_storage_unit: {},
        current: {
            inbound: {state: null},
            storage_units: [],
            active_storage_unit: {}
        },
    },
};

const getters = {
    loaded: (state) => {
        if (state.current) {
            if (state.current.inbound) {
                if (state.current.inbound.id !== null) {
                    return true;
                }
            }
        }
        return false;
    }
};

const actions = {
    async unloadActiveStorageUnit({commit, state, rootState}) {
        const request = {
            append: ['space'],
            id: state.active_storage_unit.id,
            inbound_id: state.current.inbound.id,
            action: "unload",
            gate_id: rootState.gate.id,
        };

        const item = await Vue.intranet
            .put(`storage-unit/${request.id}`, request)
            .then(response => response.data.data);

        commit('updateActiveStorageUnitSpace', item.space)
        commit('updateActiveStorageUnitState', item.state);
    },
    async storeActiveStorageUnit({commit, state}) {
        const request = {
            id: state.active_storage_unit.id,
            inbound_id: state.current.inbound.id,
            space: state.active_storage_unit.space,
            action: "store",
            append: ['space'],
        };

        const item = await Vue.intranet
            .put(`storage-unit/${request.id}`, request)
            .then(response => response.data.data)

        commit('updateActiveStorageUnitState', item.state)
    },
    async missingActiveStorageUnit({commit, state, rootState}) {
        const request = {
            id: state.active_storage_unit.id,
            inbound_id: state.current.inbound.id,
            append: ['space'],
            action: "missing",
            gate_id: rootState.gate.id,
        };

        const item = Vue.intranet
            .put(`storage-unit/${request.id}`, request)
            .then(response => response.data.data)

        commit('updateActiveStorageUnitState', item.state)
    },
    updateCurrent({commit}, payload) {
        commit('updateCurrent', payload);
    },
    reset({commit, dispatch}) {
        commit("reset");
        dispatch('setGate', null, {root: true});
    },
    updateActiveStorageUnitState({commit}, payload) {
        commit('updateActiveStorageUnitState', payload);
    },
    updateActiveStorageUnitStatus({commit}, payload) {
        commit('updateActiveStorageUnitStatus', payload);
    },
};

const mutations = {
    setActiveStorageUnit(state, nve) {
        Vue.set(state, 'active_storage_unit', state.current.storage_units.find(item => item.nve === nve))
    },
    resetActiveStorageUnit(state) {
        Vue.set(state, 'active_storage_unit', null)
    },
    updateActiveStorageUnitStatus(state, new_state) {
        Vue.set(state.active_storage_unit, 'status', new_state);
    },
    updateActiveStorageUnitSpace(state, payload) {
        Vue.set(state.active_storage_unit, 'space', payload);
    },
    updateActiveStorageUnitState(state, payload) {
        Vue.set(state.active_storage_unit, 'state', payload);
    },
    updateCurrent(state, payload) {
        Vue.set(state, 'current', payload);
    },
    updateInboundState(state, payload) {
        Vue.set(state.current.inbound, 'state', payload);
    },
    reset(state) {
        Vue.set(state.current, 'inbound', {});
        Vue.set(state.current, 'storage_units', []);
        Vue.set(state, 'active_storage_unit', {});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
