// import Vue from 'vue';
import { getField, updateField } from 'vuex-map-fields';

const state = {
    gates: [],
    warehouses: [],
};

const getters = {
    getField,
};

const actions = {

};

const mutations = {
    updateField,
    setGates(state, warehouse){
        state.gates = warehouse.gates
    },
    setWarehouses(state, warehouses){
        state.warehouses = warehouses
    },

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
