import Vue from 'vue'

Vue.filter('nve', function (value) {
    if (!value) return ''
    if (value.length != 20) return value;
    value = value.toString().substring(value.length - 8)
    return value
})

Vue.filter('timestamp', function (unix_timestamp) {
    let date = new Date(unix_timestamp * 1000);
    let format = '';

    if (date) {
        format = Intl.DateTimeFormat('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'}).format(date);
    }

    return format;
})

Vue.filter('truncate', function (value, length) {

    if (!value) {
        return;
    }

    if (value.length < length) {
        return value;
    }

    length = length - 3;

    return value.substring(0, length) + '...';
});
