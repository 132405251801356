<template>
  <div>
    <v-list dense one-line>
      <v-list-item
          v-for="file in files"
          :key="file.id"
      >
        <v-list-item-content>
          <v-list-item-title>{{ file.file_name }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <span
              @click="deleteFile(file.id)"
              icon
              ripple
          >
            <v-icon color="grey lighten-1">
              delete
            </v-icon>
          </span>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-btn
            block
            @click="$refs.file.click()"
            color="primary"
        >
          <v-icon>camera</v-icon>
        </v-btn>

      </v-list-item>
    </v-list>
    <input
        class="hidden-lg-and-down"
        accept="image/*"
        @change="addFile"
        capture="camera"
        ref="file"
        type="file"
    >
  </div>
</template>

<script>
export default {
  name: "FileCard",
  data: function () {
    return {
      files: [],
      loading: false,
      filesToUpload: [],
    };
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    id: {
      required: true,
    },
    collection: {
      type: String,
      default: 'default'
    },
    types: {
      type: Array,
      default() {
        return ['image/jpeg', 'application/pdf', 'image/jpg', 'image/png'];
      },
    }
  },
  computed: {
    dropZoneClass() {
      if (this.filesToUpload.length > 0) {
        return "progress-bar-striped progress-bar-animated";
      }
      return "";
    },
    mediaUrl() {
      return '/media/' + this.model + '/' + this.id + '/' + this.collection;
    },
  },
  watch: {
    id: {
      immediate: true,
      handler: function () {
        this.getFiles();
      }
    },
    filesToUpload: function () {
      this.upload()
    }
  },
  created() {
    this.getFiles();
  },
  methods: {
    getFiles: function () {
      let vm = this;
      this.intranet.get(vm.mediaUrl).then(response => {
        vm.files = response.data.data;
      })
    },
    deleteFile: function (id) {
      let vm = this;
      this.intranet.delete('/media/' + id).then(() => {
        vm.files = vm.files.filter(item => item.id !== id);
      });
    },
    addFile(e) {
      let droppedFiles = e.target.files;
      if (!droppedFiles) return;
      ([...droppedFiles]).forEach(f => {
        let allowed = this.types.find(type => type == f.type);
        if (allowed) {
          this.filesToUpload.push(f);
        }
      });
    },
    upload: function () {
      let vm = this;
      vm.loading = true;
      this.filesToUpload.forEach(function (file, index, object) {
        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('_method', 'put');
        vm.intranet.post(vm.mediaUrl, formData).then(response => {
          vm.files = response.data.data;
        }).finally(() => {
          object.splice(index, 1);
        });
      });
      vm.loading = false;
      this.$emit('added', vm.files)
    },
  },
}
</script>