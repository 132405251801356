import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

import store from '@/store';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return
  }
  next('/')
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next();
    return
  }
  next('/login')
};

const router = new Router({
  routes: [
    {
      path: '/',
      component: () => import('@/components/index'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/components/login'),
      beforeEnter: ifNotAuthenticated,
    },
    {
      path: '/inbound',
      name: 'inbound',
      component: () => import('@/components/inbound/'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inbound/detail/:id',
      name: "inbound_detail",
      props: true,
      component: () => import('@/components/inbound/detail'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inbound/create',
      name: "inbound_create",
      component: () => import('@/components/inbound/create'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inbound/create/storageunit/',
      name: "storageunit",
      component: () => import('@/components/inbound/create/form'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inbound/create/inbound_storage_units/',
      name: "inbound_storage_units",
      component: () => import('../components/inbound/create/inbound_storage_units.vue'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inbound/create/storageunit/:id',
      name: "storageunit_edit",
      component: () => import('@/components/inbound/create/storage_unit_article'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/outbound',
      name: 'outbound',
      component: () => import('@/components/outbound/'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/outbound/detail/:id',
      component: () => import('@/components/outbound/detail/index'),
      name: "outbound_detail",
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/outbound/create',
      component: () => import('@/components/outbound/create/'),
      name: "outbound_create",
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/outbound/create/overview/:id',
      name: "outbound_create_overview",
      props: true,
      component: () => import('@/components/outbound/create/overview'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/transfer',
      name: 'transfer',
      component: () => import('@/components/transfer/'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/transfer/detail/:id',
      name: "transfer_detail",
      component: () => import('@/components/transfer/detail'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/transfer/create',
      name: 'transfer_create',
      component: () => import('@/components/transfer/create'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/transfer/create/overview/:id',
      name: 'transfer_create_overview',
      props: true,
      component:  () => import('@/components/transfer/create/overview'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/asset',
      name: 'asset',
      component: () => import('@/components/asset/'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/asset/:id',
      name: 'asset_detail',
      props: true,
      component: () => import('@/components/asset/detail'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/tools',
      name: "tools",
      component:  () => import('@/components/tools/'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/tools/storageunit/edit/:id',
      name: "tools_storageunit_edit",
      component:  () => import('@/components/tools/storageunit/edit'),
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/tools/move',
      name: "tools_move",
      component:  () => import('@/components/tools/move'),
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/tools/exchange',
      name: "tools_exchange",
      component:  () => import('@/components/tools/exchange'),
      props: true,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/tools/info/',
      name: "tools_info",
      component:  () => import('@/components/tools/info'),
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/tools/info/:query',
      name: "tools_info_scan",
      component:  () => import('@/components/tools/info'),
      beforeEnter: ifAuthenticated,
      props: true
    },
    {
      path: '/tools/merge',
      name: "tools_merge",
      component:  () => import('@/components/tools/merge'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/tools/upload',
      name: "tools_upload",
      component:  () => import('@/components/tools/upload'),
      beforeEnter: ifAuthenticated
    },
    {
      name: 'checklist',
      path: '/checklist',
      component:  () => import('@/components/checklist/index'),
      beforeEnter: ifAuthenticated,
    },
    {
      name: 'checklist.scan',
      path: '/checklist/:scan',
      props: true,
      component:  () => import('@/components/checklist/index'),
      beforeEnter: ifAuthenticated,
    },
    {
      name: 'checklist.detail',
      path: '/checklist/:id',
      component:  () => import('@/components/checklist/index'),
      props: true,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/inventory',
      name: "inventory_index",
      component:  () => import('@/components/inventory/'),
      beforeEnter: ifAuthenticated
    },
    {
      path: '/inventory/inventory-scan/:id',
      name: "inventory_scan",
      component:  () => import('@/components/inventory/InventoryScan'),
      beforeEnter: ifAuthenticated,
      props: true
    },
  ]
});

export default router